import React from 'react'
import styled from 'styled-components'

export default function FullButton({
  title,
  action,
  border,
}) {
  return (
    <Wrapper
      className="animate pointer"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  )
}

const Wrapper = styled.button`
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  border-radius: 8px;
  font-size: 1rem;
  border: 1px solid
  ${(props) =>
    props.border ? 'transparent' : 'transparent'};
  background-color: ${(props) =>
    props.border ? 'rgba(0, 0, 0, 0.7)' : '#333'};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) =>
    props.border ? '#fff' : '#fff'};
  :hover {
    background-color: ${(props) =>
      props.border
        ? 'rgba(91, 75, 59, 0.5)'
        : 'rgba(91, 75, 59, 0.5)'};
    border: 1px solid transparent;
    color: ${(props) =>
      props.border ? '#fff' : '#fff'};
  }
  
  }
`
