import React, { Suspense } from 'react'
// import styled from 'styled-components'

// Sections
import Header from '../components/sections/Header'
import About from '../components/sections/About'
import Contact from '../components/sections/Contact'
import Testimonials from '../components/sections/Testimonials'

// import logo from '../assets/images/TPG-logo-black.webp'

const Gallery = React.lazy(() =>
  import('../components/sections/Gallery')
)

const Brands = React.lazy(() =>
  import('../components/sections/Brands')
)

export default function Landing() {
  return (
    <>
      <Header />
      <About />
      <Suspense
        fallback={
          <div
            style={{
              background: 'white',
              height: '100vh',
            }}
          ></div>
        }
      >
        <Gallery />
      </Suspense>
      <Suspense
        fallback={
          <div
            style={{
              background: 'white',
              height: '100vh',
            }}
          ></div>
        }
      >
        <Brands />
      </Suspense>
      <Testimonials />
      <Contact />
    </>
  )
}

// const ImageWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   position: relative;
//   top: -4rem;

//   animation: fade-in 4s ease-in-out forwards; /* Animation for fading in */
// }

// @keyframes fade-in {
//   from {
//     opacity: 0; /* Start with transparent */
//   }
//   to {
//     opacity: 1; /* End with solid */
//   }
// }
// `

// const Img = styled.img`
//   width: 550px;
//   height: 450px;

//   @media (max-width: 820px) {
//     width: 450px;
//     height: 350px;
//   }

//   @media (max-width: 550px) {
//     width: 300px;
//     height: 200px;
//   }
// `
