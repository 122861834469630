import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

// Screens
import TopNavbar from './components/nav/TopNavBar'
import Footer from './components/sections/Footer'
import Landing from './screens/Landing'
import NotFound from './screens/NotFound'

export default function App() {
  useEffect(() => {
    document.cookie =
      'cookieName=value; SameSite=None; Secure'
  }, [])
  return (
    <>
      <TopNavbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  )
}
