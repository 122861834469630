import React from 'react'
import styled from 'styled-components'

//Components
import Form from './Form'

export default function Contact() {
  return (
    <Wrapper id="contact" className="container">
      <LeftSide>
        <h1 className="font40 bold">
          Contact us
        </h1>
        <h2 className="font20 bold">
          Fill out the form or send a text
        </h2>
        <FormContainer>
          <Form />
        </FormContainer>
      </LeftSide>
      <RightSide>
        <ContactLinks>
          <h2 className="font20 bold">Links</h2>
          <br />
          <ul>
            <li>
              <a href="tel:+64273658483">
                <ContactLinkIcon
                  className="fa fa-phone"
                  alt="Phone"
                />
                +64 27 365 8483
              </a>
            </li>
            <li>
              <a href="mailto:luke@thepainterguy.co.nz">
                <ContactLinkIcon
                  className="fa fa-envelope"
                  alt="Email"
                />
                luke@thepainterguy.co.nz
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/thepainterguy/">
                <ContactLinkIcon className="fa fa-facebook" />
                The Painter Guy
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/_thepainterguy/">
                <ContactLinkIcon className="fa fa-instagram" />
                @_thepainterguy
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/the-painter-guy-nz/">
                <ContactLinkIcon className="fa fa-linkedin" />
                the-painter-guy-nz
              </a>
            </li>
          </ul>
        </ContactLinks>
      </RightSide>
    </Wrapper>
  )
}
const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  min-height: 90vh;
  padding: 5% 5%;
  @media (max-width: 960px) {
    flex-direction: column;
    padding: 10% 10%;
  }
  @media (max-width: 820px) {
    padding: 20% 10%;
  }

  @media (max-width: 560px) {
    .radius8 {
      width: 5%;
    }
  }
`

const LeftSide = styled.div`
  flex: 1;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    text-align: left;
  }
`

const RightSide = styled.div`
  width: 50%;
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 3em;
  padding-left: 15em;

  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    justify-content: flex-start;
    padding-left: 0;
  }
`

const FormContainer = styled.div`
  width: 100%;
`

const ContactLinks = styled.div`
  li {
    margin: 3% 0;
    white-space: nowrap;
  }
  a {
    text-decoration: none !important;
    color: darkgray;
    transition: all 0.3s ease;
  }
  a:hover {
    color: #333;
  }
`

const ContactLinkIcon = styled.i`
  margin: 10px;
  transform: scale(1.5);
`
