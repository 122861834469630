import React from 'react'
import styled from 'styled-components'
import Textimonials from './../elements/Textimonials'

const testimonialsData = [
  {
    author: '- J Hawkins',
    text: '"Absolutely thrilled with the exceptional painting services provided by The Painter Guy! Their attention to detail and precision transformed my home into a masterpiece. Professional and courteous team, efficient work, and stunning results. Highly recommend them for any painting needs!"',
  },
  {
    author: '- S Ryan',
    text: '"We were very happy with the work that was completed on our interior. Luke checked in throughout the painting process to make sure we were happy. They did a fabulous job, and were even accomodating when we changed our minds on a colour. We would definitely recommend The Painter Guy."',
  },
  {
    author: '- H Ngatai',
    text: `"Choosing The Painter Guy for our office renovation was a game-changer! Luke's expertise and skillful execution turned our office space into a vibrant and welcoming environment. The team's professionalism, timely completion, and impeccable finish exceeded our expectations."`,
  },
]

const Testimonials = () => {
  return (
    <Wrapper
      id="testimonials"
      className="container"
    >
      <h1 className="font40 extraBold">
        Testimonials
      </h1>

      <p className="font18 bold">
        We are proud of the work we do and love to
        hear feedback from our clients. Here are
        some of the things they have said about
        us.
      </p>
      <br />
      <Textimonials
        testimonials={testimonialsData}
      />
    </Wrapper>
  )
}

export default Testimonials

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 5% 5%;
  @media (max-width: 960px) {
    padding: 10% 10%;
  }
  @media (max-width: 820px) {
    padding: 20% 10%;
  }

  h1 {
    margin-bottom: 3%;
  }
`
