import React from 'react'
import styled from 'styled-components'

const Testimonials = ({ testimonials }) => {
  return (
    <TestimonialsContainer>
      {testimonials.map((testimonial, index) => (
        <Testimonial key={index}>
          <TestimonialText className="font15 regular">
            {testimonial.text}
          </TestimonialText>
          <TestimonialAuthor className="upper-case">
            {testimonial.author}
          </TestimonialAuthor>
        </Testimonial>
      ))}
    </TestimonialsContainer>
  )
}

export default Testimonials

const TestimonialsContainer = styled.div`
  margin: 5% 5%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`

const Testimonial = styled.div`
  width: 500px;
  height: 200px;
  padding: 1rem;

  @media (max-width: 600px) {
    width: 90%;
    margin-top: 2rem;
  }
`

const TestimonialAuthor = styled.p`
  text-align: center;
  margin-top: 5%;
`

const TestimonialText = styled.p`
  line-height: 1.5;
`
