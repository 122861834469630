import React from 'react'
import styled from 'styled-components'

export default function About() {
  return (
    <div>
      <Wrapper id="about" className="container">
        <h1 className="font40 bold ">About us</h1>

        <p className="font18 bold">
          The Painter Guy is a dedicated,
          full-service residential and commercial
          painting company serving the Wellington
          Region since 2013. Our team specializes
          in providing high-quality paint
          solutions for both interior and exterior
          applications.
        </p>
        <div className="font15 regular">
          <br />
          <p>
            {' '}
            With a strong focus on delivering
            exceptional results, our painters work
            closely with owner and experienced
            painter, Luke Grindlay. Luke's 10+
            years in the business, coupled with
            his passion for transforming
            properties, have earned The Painter
            Guy an excellent reputation and a long
            list of satisfied clients.
            <br />
            <br />
            We understand that your property is
            your most valuable asset, and we treat
            it as such. Our experts use premium
            products specifically designed for
            each surface, ensuring maximum
            protection and longevity. From
            meticulous home renovations to
            refreshing commercial spaces, we cover
            the entire spectrum of painting and
            decorating.
          </p>
          <br />
          <div className="grid-container">
            <ul>
              <li>
                <span className="fas fa-check"></span>{' '}
                Interior Painting
              </li>
              <li>
                <span className="fas fa-check"></span>{' '}
                Exterior Painting
              </li>
              <li>
                <span className="fas fa-check"></span>{' '}
                Colour Consultation
              </li>
            </ul>
            <ul>
              <li>
                <span className="fas fa-check"></span>{' '}
                Pressure Powerwashing
              </li>
              <li>
                <span className="fas fa-check"></span>{' '}
                Commercial Painting
              </li>
              <li>
                <span className="fas fa-check"></span>{' '}
                Surface Preparation
              </li>
            </ul>
          </div>
          <br />
          <p className="font15 regular">
            We strive to make the entire process
            easy and convenient for you. From cost
            estimation to feedback, we take care
            of it all. Simply get in touch with
            us, and we will provide you with a
            competitive price for your next
            painting project.
            <br />
            <br />
            Choose The Painter Guy for your
            painting needs and experience the
            difference we can make in transforming
            your space.
          </p>
        </div>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 5% 5%;
  @media (max-width: 960px) {
    padding: 10% 10%;
  }
  @media (max-width: 820px) {
    padding: 20% 10%;
  }

  h1 {
    margin-bottom: 3%;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    max-width: 100%;
  }

  li {
    font-size: 1rem;
    font-weight: 900;
    margin: 1rem;

    @media (max-width: 480px) {
      margin: 0 0 1rem 0;
    }
  }

  span {
    color: #8e4626;
  }

  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`
