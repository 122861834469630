import React from 'react'
import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'
// Assets
import CloseIcon from '../../assets/svg/CloseIcon'

export default function Sidebar({
  sidebarOpen,
  toggleSidebar,
}) {
  return (
    <Wrapper
      className="animate whiteBg"
      sidebarOpen={sidebarOpen}
    >
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter"></div>
        <CloseBtn
          title="Close menu"
          className="animate pointer"
          onClick={() =>
            toggleSidebar(!sidebarOpen)
          }
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() =>
              toggleSidebar(!sidebarOpen)
            }
            activeclass="active"
            to="/#home"
            spy={'true'}
            smooth={true}
            offset={-60}
          >
            Home
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() =>
              toggleSidebar(!sidebarOpen)
            }
            activeclass="active"
            to="/#about"
            spy={'true'}
            smooth={true}
            offset={-60}
          >
            About
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() =>
              toggleSidebar(!sidebarOpen)
            }
            activeclass="active"
            to="/#gallery"
            spy={'true'}
            smooth={true}
            offset={-60}
          >
            Gallery
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() =>
              toggleSidebar(!sidebarOpen)
            }
            activeclass="active"
            to="/#brands"
            spy={'true'}
            smooth={true}
            offset={-60}
          >
            Brands
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() =>
              toggleSidebar(!sidebarOpen)
            }
            activeclass="active"
            to="/#testimonials"
            spy={'true'}
            smooth={true}
            offset={-60}
          >
            Testimonials
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() =>
              toggleSidebar(!sidebarOpen)
            }
            activeclass="active"
            to="/#contact"
            spy={'true'}
            smooth={true}
            offset={-60}
          >
            Contact
          </Link>
        </li>
      </UlStyle>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  li {
    margin: 20px 0;
  }

  a {
    text-decoration: none !important;
    color: #333;

    transition: all 0.3s ease;
  }

  a:hover {
    color: #333;
  }
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(
    100px
  ); /* Safari support */
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) =>
    props.sidebarOpen ? '0px' : '-400px'};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`
const SidebarHeader = styled.div`
  padding: 20px 0;
`
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`
