import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'

// Components
import Sidebar from './Sidebar'
import Backdrop from '../elements/Backdrop'

// Assets
import BurgerIcon from '../../assets/svg/BurgerIcon'
import logo from '../../assets/images/tpg-logo-nav.png'

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY)
  const [sidebarOpen, toggleSidebar] =
    useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () =>
      setY(window.scrollY)
    )
    return () => {
      window.removeEventListener('scroll', () =>
        setY(window.scrollY)
      )
    }
  }, [y])

  return (
    <>
      <Sidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      {sidebarOpen && (
        <Backdrop toggleSidebar={toggleSidebar} />
      )}

      <Wrapper
        className="flexCenter animate"
        style={
          y > 100
            ? { height: '60px' }
            : { height: '80px' }
        }
      >
        <NavInner className="container flexSpaceCenter">
          <Link
            activeclass="active"
            to="/#home"
            spy={'true'}
            smooth={true}
            offset={-80}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: '200px',
                height: '30px',
              }}
            />
          </Link>
          <BurgerWrapper
            title="Open menu"
            className="pointer"
            onClick={() =>
              toggleSidebar(!sidebarOpen)
            }
          >
            <BurgerIcon />
          </BurgerWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="regular font15 pointer">
              <Link
                activeclass="active"
                style={{
                  padding: '10px 15px',
                }}
                to="/#home"
                spy={'true'}
                smooth={true}
                offset={-80}
              >
                Home
              </Link>
            </li>
            <li className="regular font15 pointer">
              <Link
                activeclass="active"
                style={{
                  padding: '10px 15px',
                }}
                to="/#about"
                spy={'true'}
                smooth={true}
                offset={-80}
              >
                About
              </Link>
            </li>
            <li className="regular font15 pointer">
              <Link
                activeclass="active"
                style={{
                  padding: '10px 15px',
                }}
                to="/#gallery"
                spy={'true'}
                smooth={true}
                offset={-80}
              >
                Gallery
              </Link>
            </li>
            <li className="regular font15 pointer">
              <Link
                activeclass="active"
                style={{
                  padding: '10px 15px',
                }}
                to="/#brands"
                spy={'true'}
                smooth={true}
                offset={-80}
              >
                Brands
              </Link>
            </li>
            <li className="regular font15 pointer">
              <Link
                activeclass="active"
                style={{
                  padding: '10px 15px',
                }}
                to="/#testimonials"
                spy={'true'}
                smooth={true}
                offset={-80}
              >
                Testimonials
              </Link>
            </li>
            <li className="regular font15 pointer">
              <Link
                activeclass="active"
                style={{
                  padding: '10px 15px',
                }}
                to="/#contact"
                spy={'true'}
                smooth={true}
                offset={-80}
              >
                Contact
              </Link>
            </li>
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.nav`
  background-color: rgba(91, 75, 59, 0.5);

  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  a {
    text-decoration: none !important;
    color: #fff;

    transition: all 0.3s ease;
  }

  a:hover {
    color: #333;
  }
`
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurgerWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 0.5%;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`
