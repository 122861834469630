import React from 'react'
import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'

// Assets
import logo from '../../assets/images/tpg-logo-white.webp'
import headerBgImage from '../../assets/images/header-bg-image.webp'

// Components
import FullButton from '../buttons/FullButton'

export default function Header() {
  return (
    <BackgroundImage>
      <Wrapper id="home" className="container">
        <RightSide>
          <ImageWrapper>
            <Img
              className="radius8"
              src={logo}
              alt="The Painter Guy logo"
            />
          </ImageWrapper>
        </RightSide>
        <LeftSide>
          <ButtonsRow className="flexNullCenter">
            <div style={{ width: '180px' }}>
              <Link
                activeclass="active"
                to="/#contact"
                spy={'true'}
                smooth={true}
                offset={-80}
              >
                <FullButton
                  title="Get a quote"
                  border={true}
                />
              </Link>
            </div>
            <div
              style={{
                width: '180px',
                marginLeft: '15px',
              }}
            >
              <Link
                activeclass="active"
                to="/#gallery"
                spy={'true'}
                smooth={true}
                offset={-80}
              >
                <FullButton
                  title="View gallery"
                  border={true}
                />
              </Link>
            </div>
          </ButtonsRow>
        </LeftSide>
      </Wrapper>
    </BackgroundImage>
  )
}

const BackgroundImage = styled.div`
  background-image: url(${headerBgImage});
  background-size: cover;
  background-position: center;
  height: 100vh; /* Adjust the height as needed */
  opacity: 0; /* Initial opacity value */
  animation: fade-in 2s ease-in-out forwards; /* Animation for fading in */
}

@keyframes fade-in {
  from {
    opacity: 0; /* Start with transparent */
  }
  to {
    opacity: 1; /* End with solid */
  }
}
`

const Wrapper = styled.section`
  padding: 10% 5%;
  min-height: 100vh;

  @media (max-width: 960px) {
    padding: 15% 10%;
  }

  @media (max-width: 768px) {
    padding: 20% 10%;
  }

  @media (max-width: 480px) {
    padding: 30% 5%;
  }

  @media (max-width: 320px) {
    padding: 40% 10%;
  }
`

const LeftSide = styled.div`
  width: 100%;
  h1 {
    color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`

const RightSide = styled.div`
  width: 100%;
  @media (max-width: 960px) {
    margin-top: 10%;
  }

  @media (max-width: 768px) {
    margin-top: 20%;
  }

  @media (max-width: 480px) {
    margin-top: 30%;
  }

  @media (max-width: 320px) {
    margin-top: 40%;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  animation: fade-in 4s ease-in-out forwards; /* Animation for fading in */
}

@keyframes fade-in {
  from {
    opacity: 0; /* Start with transparent */
  }
  to {
    opacity: 1; /* End with solid */
  }
}
`

const Img = styled.img`
  width: 650px;
  height: 450px;

  @media (max-width: 820px) {
    width: 550px;
    height: 350px;
  }

  @media (max-width: 550px) {
    width: 450px;
    height: 250px;
  }

  @media (max-width: 480px) {
    width: 330px;
    height: 230px;
  }
`

const ButtonsRow = styled.div`
  padding-top: 5%;
`
